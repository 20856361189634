import APIService from "./api"

export const getSession = async (session_id) => {
  try {
      const response = await APIService.get(`/subscription/session/${session_id}`)
      console.log("[API] getSession response:", response.ok)
      if (response.ok) return response.data
      return []
  } catch (error) {
      console.error("There were problems with the api", error)
  }
}

export const getSubscription = async () => {
  try {
      const response = await APIService.get(`/subscription`)
      console.log("[API] getSubscription response:", response.ok)
      if (response.ok) return response.data
      return {data: null}
  } catch (error) {
      console.error("There were problems with the api", error)
  }
}

export const updateSubscription = async (id) => {
  try {
    const response = await APIService.post(`/subscription/${id}`)
    console.log("[API] updateSubscription response:", response.ok)
    if (response.ok) return response.data
    return []
  } catch (error) {
      console.error("There were problems with the api", error)
  }
}

export const deteleSubscription = async (id) => {
  try {
    const response = await APIService.delete(`/subscription/${id}`)
    console.log("[API] deleteSubscription response:", response.ok)
    if (response.ok) return response.data
    return []
  } catch (error) {
      console.error("There were problems with the api", error)
  }
}
