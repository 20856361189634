import { SignUp } from "@clerk/clerk-react";
import { ROUTES } from "../constants";

const MySignUp = () => {
  return <SignUp 
            afterSignUpUrl={`${window.location.origin}${ROUTES.MY_DETAILS}`}
            signInUrl={`${window.location.origin}${ROUTES.SIGNIN}`}
         />;
};

export default MySignUp;