import { create } from 'apisauce'
import { getConfig } from '../config'

const config = getConfig();

const APIService = create({
  baseURL: `${config.API_URL}api/v1/`,
  headers: { Accept: 'application/vnd.github.v3+json' },
})
APIService.setHeader("FieldMate-App-Version", `*`)

export const setAPIToken = async (token) => {
  if (token) {
    APIService.setHeader("FM-Authorization", `Bearer ${token}`)
  }
}

export default APIService;