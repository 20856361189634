import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";

const Cancel = () => {
  const navigate = useNavigate()

  useEffect(() => {
    //TODO: Check if we have to do something special
    navigate(ROUTES.SUBSCRIPTION)
  }, [])
  
  

  return (
    <div>Cancel</div>
  );
};

export default Cancel;