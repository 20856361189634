
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ROUTES, US_STATES } from "../constants";
import { useUser, useOrganization } from "@clerk/clerk-react";

const MyDetailsEdit = () => {
  const { user} = useUser();
  const { organization } = useOrganization();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const navigate = useNavigate();
  if(!organization){
    navigate(ROUTES.ORGANIZATION);
    return
  }
  const org = user.organizationMemberships.find(o => o.organization.name == organization.name);
  
  const handleUpdate = (data) => {
    setIsSubmitted(true);
    user.update({
      unsafeMetadata: data
    })
    setTimeout(()=>
      navigate(ROUTES.MY_DETAILS)
    ,2000)
  }

  return <div>
      <div className="profile">
        {user && org &&
        <>
          <h2 className="title">My Details</h2>
          <p className="sub-title">Welcome {user.firstName} {user.lastName} to your profile</p>
          <form onSubmit={handleSubmit(handleUpdate)} className="my-form needs-validation">
            <div className="mb-3 has-validation">
              <label htmlFor="exampleFormControlInput1" className="form-label">Trade</label>
              <select className="form-select" aria-label="Default select example" {...register("trade", { required: true, value: user.unsafeMetadata.trade })}>
                <option selected vlaue="">Select trade</option>
                <option value="HVAC">HVAC</option>
              </select>
              {/*<input type="text" className="form-control" placeholder="trade" {...register("trade", { required: true, value: user.unsafeMetadata.trade })} />*/}
              {errors.trade && <div className="invalid-feedback" style={{display: "flex"}}>Trade is required</div>}
            </div>
            <div className="mb-3">
              <label htmlFor="exampleFormControlInput1" className="form-label">Role</label>
              <select className="form-select" aria-label="Default select example" {...register("role", { required: true, value: user.unsafeMetadata.role })}>
                <option selected vlaue="">Select role</option>
                <option value="Team member">Team member</option>
                <option value="Team leader">Team leader</option>
              </select>
              {/*<input type="text" className="form-control" placeholder="role" {...register("role", { required: true, value: user.unsafeMetadata.role })}/>*/}
              {errors.role && <div className="invalid-feedback" style={{display: "flex"}}>Role is required</div>}
            </div>
            {org.role == 'org:admin' &&
              <>
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Company Name</label>
                  <input type="text" className="form-control" placeholder="company name" {...register("company_name", { required: true, value: user.unsafeMetadata.company_name})}/>
                  {errors.company_name && <div className="invalid-feedback" style={{display: "flex"}}>Comany name is required</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Company City</label>
                  <input type="text" className="form-control" placeholder="company city"{...register("company_city", { required: true, value: user.unsafeMetadata.company_city})}/>
                  {errors.company_city && <div className="invalid-feedback" style={{display: "flex"}}>Company city is required</div>}
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleFormControlInput1" className="form-label">Company State</label>
                  {/*<input type="text" className="form-control" placeholder="company state" {...register("company_state", { required: true, value: user.unsafeMetadata.company_state})}/>*/}
                  <select className="form-select" aria-label="Default select example" {...register("company_state", { required: true, value: user.unsafeMetadata.company_state })}>
                    <option selected vlaue="">Select State</option>
                    {US_STATES.map(s => <option key={s} value={s}>{s}</option>)}
                  </select>
                  {errors.company_state && <div className="invalid-feedback" style={{display: "flex"}}>Company state is required</div>}
                </div>
              </>
            }
            
            { !isSubmitted ?
              <button className="btn btn-clerk">SAVE</button>:
              <button className="btn btn-clerk">
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span role="status">Loading...</span>
              </button>
            }
          </form>
        </>
        }
      </div>
    </div>;
};

export default MyDetailsEdit;