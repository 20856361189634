import { useState, useEffect } from 'react';
import { getSubscription, deteleSubscription, updateSubscription } from '../../service/subscription';
import { useUser, useOrganization } from "@clerk/clerk-react";
import { getConfig } from '../../config';

const config = getConfig();

const Subscription = () => {
  const postUrl = `${config.API_URL}api/v1/subscription/create-checkout-session`;
  const postManageUrl = `${config.API_URL}api/v1/subscription`;

  const [activeSubscripton, setActiveSubscription] = useState('loading');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { user } = useUser();
  const { organization } = useOrganization();

  const getSubscriptionAsync = async() => {
    const response = await getSubscription();
    const org = user.organizationMemberships.find(o => o.organization.name == organization?.name);
    setActiveSubscription({active: response.data, role: org?.role})
  }

  const deteleSubscriptionAsync = async(e, id) => {
    e.preventDefault();
    await deteleSubscription(id);
    getSubscriptionAsync();
  }

  const updateSubscriptionAsync = async(e, id) => {
    e.preventDefault();
    await updateSubscription(id);
    //setActiveSubscription({active: null, role: activeSubscripton.role})
  }

  

  const drawButton = () => {
    if (!isSubmitted){
      return <button className="btn btn-clerk" onClick={()=> setIsSubmitted(true)}>Activate Your Subscription</button>
    }
    else {
      return (
        <button className="btn btn-clerk">
          <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
          <span role="status">Loading...</span>
        </button>
      )
    }
  }

  const drawStatus = () => {
    if (activeSubscripton.active.status == "ACTIVE")
      return <span className="badge bg-secondary">Your Subscription is Active</span>
    if (activeSubscripton.active.status == "CANCELLING")
      return <span className="badge text-bg-warning">Your subscription will end on {activeSubscripton.active.current_period_end}</span>
  }

  const drawBox = () => {
    if (!activeSubscripton.active && activeSubscripton.role !== 'org:admin')
      return (<div className="alert alert-warning" role="alert"> Your organizaiton does not have an active subscription. Please contact your organization's administrator.</div>)

    return (
        <form action={postUrl} method="POST">
          {!activeSubscripton.active ?
            <h5>Activate Your Subscription to Continue Using FieldMateAI</h5>:
            <span></span>
          }
          {activeSubscripton.active?.subscription_id == "FREE" ?
           <div className="alert alert-success" role="alert"> You are using a FREE subscription</div>:
          <div className="card" >
            <div className="card-header">FieldMateAI Monthly Plan</div>
            <div className="card-body">
              <h6>Pricing is based on team size: </h6>
              <p className="card-text">
                  <ul>
                    <li>Individual User -- $25.00 / month</li>
                    <li>Team of 2 to 25 Users -- $19.00 per user / month</li>
                    <li>Enterprise 26+ Users -- Contact Us</li>
                  </ul>
              </p>
              {!activeSubscripton.active ?
                drawButton():
                drawStatus()
              }
              {activeSubscripton.active && activeSubscripton.active.status == 'ACTIVE' && activeSubscripton.role == 'org:admin' &&
                <>
                  <div className='row' style={{marginTop: 20}}>
                    <div className="col">
                      <form action={postManageUrl} method="POST">
                      <input type="hidden" id="subscription_id" name="subscription_id" value={activeSubscripton.active.subscription_id}/>
                        <button className="btn btn-clerk">Manage billing</button>
                      </form>
                    </div>
                    <div className="col">
                      <button type="button" className="btn btn-clerk-danger btn-danger" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Cancel Subscription
                      </button>
                      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Cancel Subscription</h5>
                              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              Are you sure?
                            </div>
                            <div className="modal-footer">
                              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
                              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(e) => deteleSubscriptionAsync(e, activeSubscripton.active.subscription_id)}>Yes</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*<button className="btn btn-clerk-danger btn-danger" onClick={(e) => deteleSubscriptionAsync(e, activeSubscripton.active.subscription_id)}>Cancel Subscription</button>*/}
                    </div>
                    
                  </div>
                </>
                
              }
              
              
            </div>
          </div>
          }
          <input type="hidden" id="basicPrice" name="priceId" value={config.PRICE_ID}/>
          <input type="hidden" id="customer_email" name="customer_email" value={user.primaryEmailAddress}/>
        </form>)
  }

  useEffect(() => {
    getSubscriptionAsync()
  }, [])

  return (
    <>
    {activeSubscripton == 'loading' ? 
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>: 
      drawBox()
    }
    </>
  );
};

export default Subscription;