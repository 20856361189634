import { useUser, useOrganization } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants";

const MyDetails = () => {
  const { user } = useUser();
  const { organization } = useOrganization();
  const navigate = useNavigate();
  const org = user.organizationMemberships.find(o => o.organization.name == organization.name);

  return <div>
          <div className="profile">
            {user && org &&
            <>
              <div className="mydetails">
                <h2 className="title">
                  My Details
                </h2>
                <div className="row">
                  <div className="col"><div className="label">Full Name </div> {user.fullName}</div>
                </div>
                <div className="row">
                  <div className="col"><div className="label">Primary Email </div> {user.primaryEmailAddress.emailAddress}</div>
                </div>
                <div className="row">
                  <div className="col"><div className="label">Role </div>{user.unsafeMetadata.role}</div>
                </div>
                <div className="row">
                  <div className="col"><div className="label">Trade </div> {user.unsafeMetadata.trade}</div>
                </div>
                {org.role == 'org:admin' &&
                  <>
                    <h2 className="title">
                      Company Information
                    </h2>
                    <div className="row">
                      <div className="col"><div className="label">Name </div> {user.unsafeMetadata.company_name}</div>
                    </div>
                    <div className="row">
                      <div className="col"><div className="label">City </div>{user.unsafeMetadata.company_city}</div>
                    </div>
                    <div className="row">
                      <div className="col"><div className="label">State </div>{user.unsafeMetadata.company_state}</div>
                    </div>
                  </>
                }
                
                <div className="mb-3">
                  <a className="col-3 btn btn-clerk" onClick={() => { navigate(ROUTES.MY_DETAILS_EDIT)}}>EDIT</a>
                </div>
              </div>
              
            </>
            }
          </div>
        </div>;
};

export default MyDetails;