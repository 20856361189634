import { SignIn, useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "../constants";

const MySignIn = () => {
  const navigate = useNavigate();
  const { isLoaded, isSignedIn, user } = useUser();
  if(isLoaded && isSignedIn){
    navigate(ROUTES.MY_DETAILS);
  }
  return <SignIn 
            afterSignInUrl={`${window.location.origin}`}
            signUpUrl={`${window.location.origin}${ROUTES.SIGNGUP}`}
         />;
};

export default MySignIn;