import {useEffect, useState} from 'react';
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useAuth, useUser, useOrganizationList, useOrganization } from "@clerk/clerk-react";
import { ROUTES } from "../constants";
import { setAPIToken } from '../service/api';
import { getSubscription } from '../service/subscription';

const ProtectedLayout = () => {
  const { setActive } = useOrganizationList();
  const navigate = useNavigate();
  const location = useLocation()
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(true);
  const { isLoaded, isSignedIn, user } = useUser();
  const { organization } = useOrganization();
  const { isLoaded: isLoadedAuth, isSignedIn: isSignedInAuth, getToken } = useAuth();

  const callback = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        console.log(`We are displaying ${entry.target}`);
      }
    });
  };

  const startObserving = (domNode, idLookFor, ) => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(function (mutation) {
        const elementAdded = Array.from(mutation.addedNodes).some(
          element => {
            /*if(!location.pathname.includes('organization_management')){
              var x = document.getElementById("cl-tab-1");
              if (x) x.style.display = "none";
              var skip = document.getElementsByClassName("cl-formButtonReset");
              if (skip.length > 0 ) skip[0].click();
            }*/
          },
        );
      });
    });
  
    observer.observe(domNode, {
      childList: true,
      attributes: true,
      characterData: true,
      subtree: true,
    });
  
    return observer;
  }

  const getTokenAsync = async () => {
    if(isSignedIn){
      const jwt = await getToken({template: 'base'})   
      localStorage.setItem('clerk-jwt-template', jwt);
      setAPIToken(jwt);
      setToken(jwt)
      setLoading(true);
      if(!organization && user.organizationMemberships.length > 0){
        await setActive({organization: user.organizationMemberships[0].organization.id});
      }
      if(!location.pathname.includes('success')){
        // No Organization
        if (user.organizationMemberships.length == 0){
          setLoading(false);
          navigate(ROUTES.ORGANIZATION);
          return;
        }

        // No metadata
        if(Object.keys(user.unsafeMetadata).length == 0){
          startObserving(document.body, 'cl-tab-1');
          setLoading(false);
          navigate(ROUTES.MY_DETAILS_EDIT);
          return;
        }

        // No subscription
        const response = await getSubscription();
        if(!response.data){
          startObserving(document.body, 'cl-tab-1');
          setLoading(false);
          navigate(ROUTES.SUBSCRIPTION);
          return;
        }
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    getTokenAsync()
  }, [useLocation().pathname, isSignedIn])

  if(!isSignedIn){
    navigate(ROUTES.SIGNIN);
  }

  if (loading) {
    return( <div className='custom-loading'>
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>);
  }

  return (
    <>
      <Outlet />
    </>
  )
  

};

export default ProtectedLayout;