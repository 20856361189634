import React, { useState } from 'react';
import { useSignIn, useClerk } from '@clerk/clerk-react';
 
const Reset = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [successfulCreation, setSuccessfulCreation] = useState(false);
  const [complete, setComplete] = useState(false);
  const [secondFactor, setSecondFactor] = useState(false);
  const [error, setError] = useState(null)
  const { isLoaded, signIn, setActive } = useSignIn();
  const { signOut } = useClerk();
 
  if (!isLoaded) {
    return null;
  }

  signOut();
 
  async function create(e) {
    e.preventDefault();
    await signIn
      ?.create({
        strategy: 'reset_password_email_code',
        identifier: email,
      })
      .then(_ => {
        setSuccessfulCreation(true);
      })
      .catch(err => {
        setSuccessfulCreation(true);
        console.error('error', err.errors[0].longMessage)
      });
  }
 
  async function reset(e) {
    e.preventDefault();
    await signIn
      ?.attemptFirstFactor({
        strategy: 'reset_password_email_code',
        code,
        password,
      })
      .then(result => {
        if (result.status === 'needs_second_factor') {
          setSecondFactor(true);
        } else if (result.status === 'complete') {
          setActive({ session: result.createdSessionId });
          setComplete(true);
        } else {
          setError(result)
          console.log(result);
        }
      })
      .catch(err => {
        setError(err.errors[0].longMessage)
        console.error('error', err.errors[0].longMessage)
      });
  }
 
  return (
    <div className="profile">
      <h2 className="title">Forgot Your Password?</h2>
      <form onSubmit={!successfulCreation ? create : reset} className="my-form needs-validation">
        {!successfulCreation && !complete && (
          <>
            <div className="mb-3">
              <label htmlFor='email' className="form-label">Please provide your email:</label>
              <input type='email' className="form-control" placeholder='e.g john@doe.com' value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <button className="btn btn-clerk">Send</button>
          </>
        )}
 
        {successfulCreation && !complete && (
          <>
            <div className="mb-3">
              <label htmlFor='password' className="form-label">Check email for reset code:</label>
              <input className="form-control"
                type='text'
                value={code}
                onChange={e => setCode(e.target.value)}
              />
            </div>
            
            <div className="mb-3">
              <label htmlFor='password' className="form-label">New password:</label>
              <input className="form-control"
                type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <button className="btn btn-clerk">Reset</button>
            </div>
            {error &&
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            }
          </>
        )}
 
        {complete && 'You have successfully changed your password!'}
        {secondFactor && '2FA is required, this UI does not handle that'}
      </form>
    </div>
  );
};
 
export default Reset;