import { OrganizationProfile } from "@clerk/clerk-react";
import { ROUTES } from "../constants";

const MyOrganizationManagement = () => {
  const startObserving = (domNode, ) => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(function (mutation) {
        const elementAdded = Array.from(mutation.addedNodes).some(
          element => {
            var x = document.getElementsByClassName("cl-profileSection__organizationDanger");
            if (x.length > 0 ) x[0].style.display = "none";
          },
        );
        
      });
    });
  
    observer.observe(domNode, {
      childList: true,
      attributes: true,
      characterData: true,
      subtree: true,
    });
  
    return observer;
  }

  startObserving(document.body);
  return (
     <div>
      <OrganizationProfile afterCreateOrganizationUrl={`${window.location.origin}${ROUTES.MY_DETAILS}`} />
    </div>);
};

export default MyOrganizationManagement;