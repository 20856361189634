import { Outlet, Link, useNavigate } from "react-router-dom";
import { SignOutButton, useUser, OrganizationSwitcher } from "@clerk/clerk-react";
import { dark  } from '@clerk/themes';
import { ROUTES } from "../constants";
import { getUrl } from "../utils";
import { getConfig } from "../config";

const config = getConfig();

const Layout = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const handleLogout = () =>{
    localStorage.removeItem('clerk-jwt-template');
    navigate(ROUTES.SIGNIN)
  }

  const handleNavigation = (route) => {
    navigate(route)
  }

  return (
    <div className="app">
      {user && 
        <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-dark">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <img src={getUrl("app/logo-white.png")} alt="Bootstrap" width={150}/>
            </a>
            <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link className="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"  onClick={()=> handleNavigation(ROUTES.MY_DETAILS)}>MY DETAILS</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"  onClick={()=> handleNavigation(ROUTES.PROFILE)}>MY ACCOUNT</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link"  data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"  onClick={()=> handleNavigation(ROUTES.SUBSCRIPTION)}>SUBSCRIPTION</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"  onClick={()=> handleNavigation(ROUTES.ORGANIZATION_MANAGEMENT)}>ORGANIZATION</Link>
                </li>
              </ul>
              <ul className="nav justify-content-end">
              <li className="nav-item" style={{display: "none", alignItems: "center"}}>
                <OrganizationSwitcher 
                  appearance={{baseTheme: dark}} 
                  hidePersonal={true}
                  createOrganizationMode={undefined}
                  afterSelectOrganizationUrl={`${window.location.origin}/mydetails`}/>
              </li>
              <li className="nav-item">
                  <a className="nav-link" aria-disabled="true">
                    <SignOutButton signOutCallback={handleLogout}>
                      <button className="btn btn-primary btn-clerk">Sign Out</button>
                    </SignOutButton>
                  </a>
                </li>
              </ul>
            </div>
            
          </div>
        </nav>
      }
      <div className="main">
        <Outlet />
      </div>
      
    </div>
  )
};

export default Layout;