import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ClerkProvider } from "@clerk/clerk-react";
import MyUserProfile from "./pages/my_user_profile"
import Layout from "./navigation/layout";
import ProtectedLayout from './navigation/protected_layout';
import MySignUp from "./pages/signup";
import MySignIn from "./pages/signin";
import NoPage from "./pages/no_page";
import MyDetailsEdit from "./pages/my_details_edit";
import MyDetails from "./pages/my_details";
import Subscription from "./pages/payment/subscription";
import { ROUTES } from "./constants";
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import Success from "./pages/payment/success";
import Cancel from "./pages/payment/cancel";
import Reset from "./pages/reset";
import { getConfig } from "./config";
import MyOrganization from "./pages/my_organization";
import MyOrganizationManagement from "./pages/my_organization_management";
import MyOrganizationCustom from "./pages/my_organization_custom";

const config = getConfig();
const clerkPubKey = config.CLERK_PUBLISHABLE_KEY;

function App() {
  return (
    <ClerkProvider publishableKey={clerkPubKey}>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path={ROUTES.HOME} element={<MySignIn/>} />
            <Route path={ROUTES.SIGNIN} element={<MySignIn />} />
            <Route path={ROUTES.SIGNGUP} element={<MySignUp />} />
            <Route path={ROUTES.RESET} element={<Reset />} />
            <Route element={<ProtectedLayout/>}>
              <Route path={`${ROUTES.SUBSCRIPTION_SUCCESS}/:session_id`} element={<Success />} />
              <Route path={ROUTES.MY_DETAILS} element={<MyDetails/>} />
              <Route path={ROUTES.MY_DETAILS_EDIT} element={<MyDetailsEdit />} />
              <Route path={ROUTES.PROFILE} element={<MyUserProfile />} />
              <Route path={ROUTES.SUBSCRIPTION} element={<Subscription />} />
              <Route path={ROUTES.SUBSCRIPTION_CANCEL} element={<Cancel />} />
              <Route path={ROUTES.ORGANIZATION} element={<MyOrganizationCustom />} />
              <Route path={ROUTES.ORGANIZATION_MANAGEMENT} element={<MyOrganizationManagement />} />
              <Route path={ROUTES.WILDCARD} element={<NoPage />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ClerkProvider>
  );
}

export default App;
