import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getSession } from "../../service/subscription";
import { ROUTES } from "../../constants";

const Success = () => {
  let { session_id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getSession(session_id);
      setData(data);
      setTimeout(() =>{
        navigate(ROUTES.SUBSCRIPTION)
      }, 3000)
      
    };
    fetchData()
  }, []);

  return (
    <div>
      <div className="alert alert-success" role="alert"> Subscrition created!</div>
    </div>
  );
};

export default Success;