const ENVIRONMENT_LOCAL = {
  CLERK_PUBLISHABLE_KEY: "pk_test_YnJhdmUtcGFycm90LTIyLmNsZXJrLmFjY291bnRzLmRldiQ",
  API_URL: "http://localhost:3000/",
  PRICE_ID: "price_1OFIIiEUoCXvWLEF0N67s5SC"
}

const ENVIRONMENT_DEV = {
  CLERK_PUBLISHABLE_KEY: "pk_test_YnJhdmUtcGFycm90LTIyLmNsZXJrLmFjY291bnRzLmRldiQ",
  API_URL: "https://fieldmate-dev-api.herokuapp.com/",
  PRICE_ID: "price_1OFIIiEUoCXvWLEF0N67s5SC"
}

const ENVIRONMENT_STAGING = {
  CLERK_PUBLISHABLE_KEY: "pk_test_cG9wdWxhci1zd2lmdC03OS5jbGVyay5hY2NvdW50cy5kZXYk",
  API_URL: "https://fieldmate-staging-api.herokuapp.com/",
  PRICE_ID: "price_1OFIIiEUoCXvWLEF0N67s5SC"
}

const ENVIRONMENT_PRE_PROD= {
  CLERK_PUBLISHABLE_KEY: "pk_test_dG91Y2hpbmctYWlyZWRhbGUtOTQuY2xlcmsuYWNjb3VudHMuZGV2JA",
  API_URL: "https://fieldmate-pre-production-api-201318d5575d.herokuapp.com/",
  PRICE_ID: "price_1OU67jEUoCXvWLEFH9K0RA4R"
}

const ENVIRONMENT_PROD= {
  CLERK_PUBLISHABLE_KEY: "pk_live_Y2xlcmsuZmllbGRtYXRlLmFpJA",
  API_URL: "https://my.fieldmate.ai/",
  PRICE_ID: "price_1OU67jEUoCXvWLEFH9K0RA4R"
}

const Environments = {
  'localhost:3001': ENVIRONMENT_LOCAL,
  'localhost:3000': ENVIRONMENT_LOCAL,
  'fieldmate-dev-api.herokuapp.com': ENVIRONMENT_DEV,
  'fieldmate-staging-api.herokuapp.com': ENVIRONMENT_STAGING,
  'fieldmate-pre-production-api-201318d5575d.herokuapp.com': ENVIRONMENT_PRE_PROD,
  'my.fieldmate.ai': ENVIRONMENT_PROD
}

export const getConfig = () => {
  const host = window.location.host;
  return Environments[host];

}
