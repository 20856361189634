// Form to create a new organization. The current user
// will receive the Creator role.
import { useOrganizationList } from "@clerk/clerk-react";
import { FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants";
 
export default function MyOrganizationCustom() {
  const { createOrganization, setActive } = useOrganizationList();
  const [organizationName, setOrganizationName] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async(e) => {
    e.preventDefault();
    var org = await createOrganization({ name: organizationName });
    await setActive({organization: org.id})
    navigate(ROUTES.SUBSCRIPTION)
  };
  
  return (
    <div className="profile">
      <div className="mydetails">
        <h2 className="title">
          Create Organization
        </h2>
        <div>
          <form onSubmit={handleSubmit} style={{paddingTop: 30}}>
            <div className="mb-3">
            <label htmlFor="exampleFormControlInput1" className="form-label">Organization name</label>
              <input
                className="form-control" 
                placeholder="Name"
                type="text"
                name="organizationName"
                value={organizationName}
                onChange={(e) => setOrganizationName(e.currentTarget.value)}
              />
            </div>
            <button type="submit" className="btn btn-clerk">Create organization</button>
          </form>
        </div>
      </div>
      </div>);
}