import { UserProfile } from "@clerk/clerk-react";
import { useSearchParams } from 'react-router-dom'

const MyUserProfile = (domNode) => {
  const [searchParams] = useSearchParams();

  const view = searchParams.get('view');

  const startObserving = (domNode, ) => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(function (mutation) {
        const elementAdded = Array.from(mutation.addedNodes).some(
          element => {
          var x = document.getElementsByClassName("cl-internal-33s53r");
          if (x.length > 1 ) x[1].style.display = "none";
          x = document.getElementsByClassName("cl-profileSectionPrimaryButton__emailAddresses");
          if (x.length > 0 ) x[0].style.display = "none";
          x = document.getElementsByClassName("cl-profileSection__phoneNumbers");
          if (x.length > 0 ) x[0].style.display = "none";
          var deleteDiv = document.getElementsByClassName("cl-profileSectionContent__danger");
          if (deleteDiv.length > 0 && view == 'delete') deleteDiv[0].scrollIntoView();
        },
        );
      });
    });
  
    observer.observe(domNode, {
      childList: true,
      attributes: true,
      characterData: true,
      subtree: true,
    });
  
    return observer;
  }

  startObserving(document.body);

  return (
     <div style={{height: '100%'}}>
      <UserProfile />
    </div>);
};

export default MyUserProfile;